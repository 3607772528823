<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="title-tab">
        <div class="title-tab-btns">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
          <span class="color-red">开具电子合格证，需领取电子合格证标签，需提前申请电子印章（免费），请进微信小程序“农产品质量安全承诺达标合格证”--“申请电子印章”。</span>
        </div>
      </div>
      <!-- @row-click="rowClick"
      @sort-change="sortChange" -->
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <!-- <el-table-column align="center" type="selection" width="60">
        </el-table-column> -->
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
            <el-button size="small" type="text" v-if="scope.row.status == '01'" @click="born(scope.row)">作废</el-button>
            <el-button size="small" type="text" v-if="scope.row.status == '02'" @click="reborn(scope.row)">激活</el-button>
            <!-- <el-button size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button> -->
            <!-- <el-button size="small" type="text" @click="del(scope.row, scope.$index)">删除</el-button> -->
            <!-- <el-button size="small" type="text" @click="print(scope.row, scope.$index)">打印合格证</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    </div>
    <mydialog ref="mydialog" title="请选择来源类型" width="500px">
      <div slot="content" class="my-list">
        <el-radio-group v-model="fromType" class="my-radio">
          <el-radio
            v-for="(item, index) in fromTypeList"
            :key="index"
            :label="item.value"> {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="forms-btns" slot="footer">
        <el-button type="primary" size="small" @click="closeModel()">确定</el-button>
      </div>
    </mydialog>
    <online-edit ref="editForm" @getData="getData"></online-edit>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  import mydialog from '@/components/mydialog.vue'
  import onlineEdit from './onlineEdit.vue'
  export default {
    name: 'onlineList',
    components: {
      pagination,
      breadcrumb,
      mydialog,
      onlineEdit
    },
    data() {
      let title = '开具电子合格证（激活印刷标签）'
      return {
        breadStaticData:['承诺达标合格证管理', title],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          bodyId: utils.getBodyId()
        },
        urlName: 'asccpage',
        tableHead: [{
          prop: 'productName',
          label: '产品名称',
        },{
          prop: 'issueTime',
          label: '开具日期',
          width: 150
        },{
          prop: 'allCountUnit',
          label: '数量',
          width: 120
        },{
          prop: 'allWeightUnit',
          label: '重量',
          width: 120
        },{
          prop: 'bodyName',
          label: '开证者',
          width: 150
        },{
          prop: 'linkmanPhoneNumber',
          label: '联系方式',
          width: 150
        },{
          prop: 'productionAddress',
          label: '产地',
          minWidth: 150
        }],
        loadtext: '加载中',
        pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        },
        fromType: '',
        fromTypeList: []
      }
    },
    created() {
      this.getData()
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      closeModel(){
        if(!this.fromType){
          this.message('请选择来源类型', "warning")
          return
        }
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add', {}, {
          fromType: this.fromType,
          businessBodyType: this.fromType
        })
        this.$refs.mydialog.closeModel()
      },
      async add(){
        let _this = this
        request.psgetUserAuthenticationEntity({
          bodyId: utils.getBodyId(),
          bodyName: utils.getBodyName(),
        }).then(res => {
          if(res.code == 200){
            if(res.data.flag){
              _this.addShow()
            }else{
              this.$alert(res.data?.msg || '', '提示', {
                confirmButtonText: '确定',
                callback: () => {
                }
              })
            }
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      addShow(){
        let bodyType = utils.getBodyType()
        this.fromType = ''
        if(bodyType == '12'){ //经营主体
          this.fromTypeList = [ 
            { label: '收购', value: '01' },
            { label: '预制菜或混装菜', value: '02' },
          ]
          this.$refs.mydialog.showModel()
        }else if(bodyType == '13'){ //生产/经营主体
          this.fromTypeList = [ 
            { label: '自产', value: '03' },
            { label: '收购', value: '01' },
            { label: '预制菜或混装菜', value: '02' },
          ]
          this.$refs.mydialog.showModel()
        }else if(bodyType == '11'){//生产主体
          this.$refs.editForm.initData(`新增${this.pageName}`, 'add', {}, {
            fromType: this.fromType,
            businessBodyType: this.fromType
          })
        }
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      born(data){
        let _this = this
        _this.$confirm(`作废后，消费者或收购者不能再正常扫码，作废后的电子合格证标签不可以重新激活。您是否真的需要作废本批次（${data.itemStartCode || ''}～${data.itemEndCode || ''}号段）？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          _this.reborn(data)
        }).catch(() => {})
      },
      reborn(data){
        let _this = this
        request.asccdeactive({
          certificateOfComplianceId: data.certificateOfComplianceId,
          status: data.status
        }).then(res => {
          if(res.code == 200){
            _this.message(`${data.status == '01' ? '作废' : '激活'}成功！`, "success")
            _this.getData()
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      async edit(data, index){
        let flag = await this.findwhethertosell(data.certificateOfComplianceBathCode)
        flag && this.getInfo('edit', data)
      },
      getInfo(type, data){
        let _this = this
        request.chasgetInfo(data.certificateOfComplianceId).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result,
              productUrl: result.productUrl ? result.productUrl.split(',') : [],
              inspectionReportUrl: result.inspectionReportUrl ? result.inspectionReportUrl.split(',') : [],
              thirdInspectionReportUrl: result.thirdInspectionReportUrl ? result.thirdInspectionReportUrl.split(',') : [],
              receivingNoteUrl: result.receivingNoteUrl ? result.receivingNoteUrl.split(',') : [],
              businessBodyType: result.fromType,
              promiseNum: ['不使用禁用农药兽药、停用兽药和非法添加物', '常规农药兽药残留不超标', '对承诺的真实性负责'],
              pro1: [],
              pro2: [],
              pro4: [],
              pro5: [],
              itemStartCode: result.sysCertificateOfCompliancePrintActivateDTO?.itemStartCode || '',
              itemEndCode: result.sysCertificateOfCompliancePrintActivateDTO?.itemEndCode || '',
              num: result.sysCertificateOfCompliancePrintActivateDTO?.num || ''
            }
            let gistNum = []
            if(result.gistNum1 == '01'){
              gistNum.push('gistNum1')
            }
            if(result.gistNum2 == '01'){
              gistNum.push('gistNum2')
            }
            if(result.gistNum3 == '01'){
              gistNum.push('gistNum3')
            }
            result.gistNum = gistNum
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      findwhethertosell(certificateOfComplianceBathCode){
        let _this = this
        return new Promise(resolve => {
          return request.findwhethertosell({
            certificateOfComplianceBathCode: certificateOfComplianceBathCode
          }).then(res => {
            if(res.code == 200){
              resolve(true)
            }else{
              _this.message(res.msg || '', "error")
              resolve(false)
            }
          }).catch(e => {
            resolve(false)
            console.log(e)
          })
        })
      },
      async del(data, index){
        let _this = this
        let flag = await this.findwhethertosell(data.certificateOfComplianceBathCode)
        if(!flag) return
        _this.$confirm(`确认要删除${data.productName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.psccdelete(data.certificateOfComplianceId).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success")
            }else{
              _this.message(res.msg || '', "error")
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      print(data, index){

      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                allCountUnit: (item.allCount || '') + (item.countUnit || ''),
                allWeightUnit: (item.allWeight || '') + (item.weightUnit || ''),
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      }
    },
  }
</script>
<style lang="scss" scoped>
.my-list{
  max-height: 400px;
  overflow: auto;
}
.my-radio ::v-deep .el-radio{
  margin: 10px 30px 20px 20px;
  display: block;
}
</style>